import React, { Fragment } from "react";
import { Avatar, Box, Typography, Dialog, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deletepatient } from "../../../store/patientslice";

function DeletePopUpUser({ type, handleClose, selectedRowIds, data: params }) {
  let dispatch = useDispatch();
  let dataobj = new FormData();
  dataobj.append("_method", "DELETE");
  let onDelete = (e) => {
    e.preventDefault();
    if (type === "all") {
      dispatch(deletepatient({ id: selectedRowIds, data: dataobj }));
      console.log("allll");
    } else {
      dispatch(deletepatient({ id: params.id, data: dataobj }));
      console.log("singleeee");
    }
  };
  const [t, i18n] = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      p={4}
    >
      {type === "all" && selectedRowIds.length <= 0 ? (
        <Fragment>
          <div
            classname="p-2"
            style={{ color: "red", fontSize: "22px", fontWeight: 700 }}
          >
            {t("There Are No Rows Seleceted")}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="box-icon-delete">
            <CloseIcon />
          </div>

          <Typography align="center" sx={{ py: 4, px: 2 }} variant="h5">
            {t("Are you sure you want to perform this deletion")}?
          </Typography>

          <form
            onSubmit={onDelete}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <button
              className="btn btn-cancle"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
            >
              {t("Cancel")}
            </button>
            <button className="btn btn-delete">{t("Delete")}</button>
          </form>
        </Fragment>
      )}
    </Box>
  );
}

export default DeletePopUpUser;
