import * as React from "react";
import "./DoctrorTimePickerView.scss";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs"; // Import dayjs for parsing

export default function DoctorTimePickerView({ type, value, title, onChange }) {
  console.log("Received value:", value);

  // Parse the value into a dayjs object if it's a string
  const parsedValue = value ? dayjs(value, "HH:mm:ss") : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker
          label={title || "Choose Time"}
          value={parsedValue}
          onChange={(newDate) => {
            if (newDate) {
              const formattedTime = newDate.format("HH:mm:ss"); // Ensure the correct format
              onChange(formattedTime); // Pass formatted value to parent
            }
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          ampm={false}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
