import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  createPatient,
  getSinglePatient,
  updatePAtient,
} from "../../../store/patientslice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import dayjs from "dayjs";
import {
  getAllAccessMethod,
  getAllChronicDiseases,
  getAllGenderList,
  getAllLanguageSpeaks,
} from "../../../store/commonSlice";
import CheckMultiLangu from "../../../components/CheckMultiLangu/CheckMultiLangu";

function EditCreateUser() {
  let dispatch = useDispatch();
  let { name: type, id } = useParams();
  useEffect(() => {
    console.log("test test");
    dispatch(getAllGenderList({ lang: i18n.language }));
    dispatch(getAllChronicDiseases({ lang: i18n.language }));
    dispatch(getAllAccessMethod({ lang: i18n.language }));
    dispatch(getAllLanguageSpeaks({ lang: i18n.language }));
    if (type === "edit") {
      dispatch(getSinglePatient({ id: id }));
    }
  }, []);

  const path = useNavigate();

  let { loadingLanguSpk, errorAllLanguSpk, dataLanguSpk } = useSelector(
    (state) => state.persistData.Commone
  );
  let { loadingAccses, errorAllAccses, dataAccses } = useSelector(
    (state) => state.persistData.Commone
  );
  let { loadingChronic, errorChronic, ChronicD } = useSelector(
    (state) => state.persistData.Commone
  );
  let { loadingGen, errorGen, genders } = useSelector(
    (state) => state.persistData.Commone
  );

  console.log("common dataLanguSpk", dataLanguSpk);
  console.log("common dataAccses", dataAccses);
  console.log("common ChronicD", ChronicD);
  console.log("common genders", genders);
  // useEffect(() => {
  //   if (dataCreate || dataupdate) {
  //     dispatch(resetDataCreateAction(false));
  //     dispatch(resetDataUpdateAction(false));
  //     path("/users");
  //   }
  // }, [dataCreate, dataupdate]);

  // let singleData = false;

  const [t, i18n] = useTranslation();
  let [file, setfile] = useState(null);
  let [inp, setInp] = useState("");
  let [selectedDate, setSelectedDate] = useState(null);
  console.log("selectedDate", selectedDate);

  let sendData = (e) => {
    e.preventDefault();
    if (type === "add") {
      console.log("addd");
      dispatch(createPatient({ data: inp, lang: i18n.language })).then(
        (response) => {
          if (response.payload.success) {
            path("/users");
          } else {
            console.log("false");
          }
        }
      );
    }
    if (type === "edit") {
      console.log("ediit");
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updatePAtient({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      ).then((response) => {
        if (response.payload.success) {
          path("/users");
        } else {
          console.log("false");
        }
      });
    }
  };

  let getValue = (e) => {
    // ... existing logic for other form fields

    if (e.target.name === "patients_language_speaks") {
      setInp((prev) => ({
        ...prev,
        patients_language_speaks: [e.target.value],
      })); // Parse string to integer
    } else {
      setInp((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  const handleChangeDate = (e) => {
    setInp((prev) => {
      return { ...prev, date_of_birthday: e.format("YYYY-MM-DD") };
    });
  };

  let handleClose = (e) => {
    path(-1);
  };
  // let loadingBTN = false;

  let {
    data: languspeak,
    loadingBTN,
    error,
    dataCreate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.persistData.patients);
  console.log("singleData", singleData);
  console.log("inp", inp);

  useEffect(() => {
    if (type === "edit" && singleData) {
      // Extract translations and set default values
      const translations = singleData.translations;
      console.log("translationstranslations", translations);
      const defaultValues = translations.reduce((acc, translation) => {
        acc[`translations[${translation.locale}][name]`] = translation.name;
        return acc;
      }, {});

      // Ensure selectedLanguages includes all available locales from translations
      const allLocales = translations.map((translation) => translation.locale);
      setSelectedLanguages([...new Set([...selectedLanguages, ...allLocales])]); // Remove duplicates and add new locales

      setInp({ ...defaultValues, sensitive: singleData.sensitive });
    }
  }, [type, singleData, singleData?.item?.id]);

  // Step 1: Create state to store selected languages
  const [selectedLanguages, setSelectedLanguages] = useState(["en"]);

  const toggleLanguage = (lang) => {
    if (selectedLanguages.includes(lang)) {
      // If the language is already selected, remove it
      setSelectedLanguages(selectedLanguages.filter((l) => l !== lang));
    } else {
      // If the language is not selected, add it
      setSelectedLanguages([...selectedLanguages, lang]);
    }
  };

  console.log("inp.sensitive", inp.sensitive);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />

      <HeaderComponent title={type === "edit" ? "Edit Patient" : "Add Patient"}>
        <CheckMultiLangu
          toggleLanguage={toggleLanguage}
          selectedLanguages={selectedLanguages}
        />
      </HeaderComponent>

      {
        <div className="two-box">
          {errorSingle ? (
            <ErrorCompo />
          ) : loadingSingle ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <form className="Details row" onSubmit={(e) => sendData(e)}>
              <div className="col-lg-3 col-sm-6 row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div className="det-inp">
                    <label htmlFor="file">
                      <img
                        className="b6e5"
                        src={
                          "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                        alt="..."
                      />
                      <CloudUploadIcon className="icon ms-2" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="up"
                      placeholder="name"
                      style={{ display: "none" }}
                    />
                  </div>

                  <div className="form-group d-flex gap-2 justify-content-center align-items-center w-100 me-5">
                    <p>
                      {t("Status")}:<sup className="redstar">*</sup>
                    </p>
                    <Switch
                      defaultChecked={
                        type === "edit" && singleData && singleData.status == 1
                          ? true
                          : false
                      }
                      onChange={(event) => handleSwitchChange(event, "status")}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-sm-6 row">
                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"email"}
                      type={"email"}
                      label={t("Email")}
                      name={"email"}
                      required
                      defaultValue={
                        type === "edit" ? singleData && singleData.email : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                {selectedLanguages.map(
                  (lang) =>
                    selectedLanguages.includes(lang) && (
                      <div className="col-lg-4 col-sm-6">
                        <div className="form-group mb-4 mt-4">
                          <TextField
                            id={"text"}
                            type={"text"}
                            label={t("Name") + ` (${lang})`}
                            name={`translations[${lang}][name]`}
                            required
                            value={inp[`translations[${lang}][name]`] || ""}
                            onChange={(e) => getValue(e)}
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    )
                )}

                {/* <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"text"}
                      type={"text"}
                      label={t("Last Name")}
                      name={"last_name"}
                      required
                      defaultValue={
                        type === "edit"
                          ? singleData && singleData.last_name
                          : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div> */}

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="Gender">{t("Gender")}</InputLabel>
                      <Select
                        labelId="Gender"
                        name="gender"
                        id="Role"
                        defaultValue={
                          type === "edit" && singleData ? singleData.gender : ""
                        }
                        required
                        label="Role"
                        onChange={(e) => getValue(e)}
                      >
                        {loadingGen ? (
                          // Display a loading indicator if data is still being fetched
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          // Map over the genders array to create MenuItems for each gender option
                          genders &&
                          genders?.map((gender) => (
                            <MenuItem key={gender.id} value={gender.id}>
                              {gender.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"text"}
                      type={"number"}
                      label={t("Phone Number")}
                      name={"phone_number"}
                      required
                      defaultValue={
                        type === "edit"
                          ? singleData && singleData.phone_number
                          : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="Sensitive">{t("Sensitive")}</InputLabel>
                      <Select
                        labelId="Sensitive"
                        name="sensitive"
                        id="sensitive"
                        defaultValue={
                          type === "edit" && singleData
                            ? singleData.sensitive
                            : ""
                        }
                        required
                        label={"Sensitive"}
                        onChange={(e) => {
                          if (e.target.value == "no") {
                            setInp((prev) => {
                              return { ...prev, sensitive_note: "" };
                            });
                          }
                          getValue(e);
                        }}
                      >
                        <MenuItem value={"yes"}>yes</MenuItem>
                        <MenuItem value={"no"}>no</MenuItem>
                        <MenuItem value={"maybe"}>maybe</MenuItem>
                        <MenuItem value={"dont_know"}>dont know</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {inp.sensitive !== "no" && inp.sensitive !== "" && (
                  <div className="col-lg-4 col-sm-6">
                    <div className="form-group mb-4 mt-4">
                      <TextField
                        id={"text"}
                        type={"text"}
                        label={t("Sensitive note")}
                        name={"sensitive_note"}
                        required
                        defaultValue={
                          type === "edit"
                            ? singleData && singleData.sensitive_note
                            : ""
                        }
                        onChange={(e) => getValue(e)}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="access_method_id">
                        {t("How Did You Hear About Us")}
                      </InputLabel>
                      <Select
                        labelId="How Did You Hear About Us"
                        name="access_method_id"
                        id="access_method_id"
                        required
                        label="How Did You Hear About Us"
                        onChange={(e) => getValue(e)}
                        defaultValue={
                          type === "edit" && singleData
                            ? singleData.access_method_id
                            : ""
                        }
                      >
                        {loadingAccses ? (
                          // Display loading indicator if data is still loading
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          // Map over dataAccses to dynamically create MenuItems
                          dataAccses &&
                          dataAccses.map((access) => {
                            const translation = access.translations.find(
                              (t) => t.locale === i18n.language
                            );

                            return (
                              <MenuItem key={access.id} value={access.id}>
                                {translation ? translation.name : access.name}
                              </MenuItem>
                            );
                          })
                        )}
                        {/* Static MenuItem for "etc" */}
                        {/* <MenuItem value="etc">etc</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {(inp.access_method_id === "etc" ||
                  singleData.access_method_id === "etc") && (
                  <div className="col-lg-4 col-sm-6">
                    <div className="form-group mb-4 mt-4">
                      <TextField
                        id={"text"}
                        type={"text"}
                        label={t("Here Us From")}
                        name={"clinic_note"}
                        required
                        defaultValue={
                          type === "edit"
                            ? singleData && singleData.clinic_note
                            : ""
                        }
                        onChange={(e) => getValue(e)}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"text"}
                      type={"text"}
                      label={t("Address")}
                      name={"address"}
                      required
                      defaultValue={
                        type === "edit" ? singleData && singleData.address : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Diseases")}
                  name={"sickness"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.sickness : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div> */}

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="chronic_disease_id">
                        {t("Chronic Disease")}
                      </InputLabel>
                      <Select
                        labelId="Chronic Disease"
                        name="chronic_disease_id"
                        id="chronic_disease_id"
                        required
                        label="Chronic Disease"
                        onChange={(e) => getValue(e)}
                        defaultValue={
                          type === "edit" && singleData
                            ? singleData.chronic_disease_id
                            : ""
                        }
                      >
                        {loadingChronic ? (
                          // Display loading indicator if data is still loading
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          // Map over ChronicD to dynamically create MenuItems
                          ChronicD &&
                          ChronicD.map((disease) => {
                            const translation = disease.translations.find(
                              (t) => t.locale === i18n.language
                            );

                            return (
                              <MenuItem key={disease.id} value={disease.id}>
                                {translation ? translation.name : disease.name}
                              </MenuItem>
                            );
                          })
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="patients_language_speaks">
                        {t("language speaks")}
                      </InputLabel>
                      <Select
                        labelId="language speaks"
                        name="patients_language_speaks"
                        id="patients_language_speaks"
                        required
                        label="language speaks"
                        onChange={(e) => getValue(e)}
                        defaultValue={
                          type === "edit" && singleData
                            ? singleData?.patients_language_speaks[0].id
                            : ""
                        }
                      >
                        {loadingLanguSpk ? (
                          // Show a loading spinner when data is still being loaded
                          <MenuItem disabled>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          dataLanguSpk &&
                          dataLanguSpk.map((language) => {
                            // Find the correct translation based on current language
                            const translation = language.translations.find(
                              (t) => t.locale === i18n.language
                            );

                            return (
                              <MenuItem key={language.id} value={language.id}>
                                {translation ? translation.name : language.name}
                              </MenuItem>
                            );
                          })
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* <div className="col-lg-4 col-sm-6">
                  <div className="form-group mb-4 mt-4">
                    <TextField
                      id={"text"}
                      type={"text"}
                      label={t("Address 2")}
                      name={"location_details"}
                      required
                      defaultValue={
                        type === "edit"
                          ? singleData && singleData.location_details
                          : ""
                      }
                      onChange={(e) => getValue(e)}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div> */}
                <div className="col-lg-4 col-sm-6">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <InputLabel id="Date Of Birth">
                      {t("Date Of Birth")}:
                    </InputLabel>
                    <DatePicker
                      onChange={(newValue) => {
                        handleChangeDate(newValue);
                      }}
                      defaultValue={
                        type === "edit"
                          ? dayjs(singleData && singleData.date_of_birthday) // Format if necessary
                          : null
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div
                className="btn-lest mt-5"
                style={{ margin: "40px !important", justifyContent: "center" }}
              >
                {loadingBTN ? (
                  <button
                    type="text"
                    disabled
                    className="btn btn-primary-rgba "
                  >
                    <CheckCircleIcon color="#fff" /> {t("Loading")}...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary-rgba">
                    <CheckCircleIcon color="#fff" />
                    {type === "edit" ? t("Edit") : t("Craete")}
                  </button>
                )}

                <span onClick={handleClose} className="btn btn-danger-rgba">
                  <DoNotDisturbAltIcon color="#fff" /> {t("Back")}
                </span>
              </div>
            </form>
          )}
        </div>
      }
    </div>
  );
}

export default EditCreateUser;
