import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import Navbar from "../../../components/Navbar/Navbar";
import AddIcon from "@mui/icons-material/Add";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AvilableTime from "../../../components/AvilableTime/AvilableTime";
import {
  getAllCategory,
  getAllGenderList,
  getAllWeekdays,
} from "../../../store/commonSlice";
import {
  createDoctor,
  fetchDoctorById,
  updateDoctor,
} from "../../../store/doctorsSlice";
import { useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import CheckMultiLangu from "../../../components/CheckMultiLangu/CheckMultiLangu";
import dayjs from "dayjs";
import AvailabilityManager from "../../../components/AvailabilityManager/AvailabilityManager";
import { notifyError } from "../../../Notification";

function EditCreateDoctor() {
  let dispatch = useDispatch();
  let { name: type, id } = useParams();
  const [t, i18n] = useTranslation();
  let { data: dataAuth } = useSelector((state) => state.persistData.auth);
  console.log("dataAuth", dataAuth);
  let [inp, setInp] = useState({
    company_id: dataAuth?.user?.company_id,
    doctor_contacts: [],
    doctors_categories: [],
  });

  const [selectedDays, setSelectedDays] = useState([]);

  const handleDaySelection = (selectedDay, index) => {
    setSelectedDays((prevSelectedDays) => {
      // Find the index of the selectedDay in the array
      const existingIndex = prevSelectedDays.findIndex(
        (day) => day.index === index
      );

      // If the selectedDay already exists in the array, update its value
      if (existingIndex !== -1) {
        const updatedSelectedDays = [...prevSelectedDays];
        updatedSelectedDays[existingIndex] = { day: selectedDay, index };
        return updatedSelectedDays;
      } else {
        // If the selectedDay is not in the array, add it
        return [...prevSelectedDays, { day: selectedDay, index }];
      }
    });
  };

  let [DataAppo, setDataAppo] = useState([{}]);

  let getdataAppo = (e, type, index) => {
    setDataAppo((prev) => {
      const newDataAppo = [...prev];
      let currentItem = newDataAppo[index] || {};

      if (type === "start") {
        currentItem = { ...currentItem, start_time: e };
      } else if (type === "end") {
        currentItem = { ...currentItem, end_time: e };
      } else {
        currentItem = { ...currentItem, [e.target.name]: e.target.value };
        handleDaySelection(e.target.value, index);
      }

      newDataAppo[index] = currentItem;
      return newDataAppo;
    });
  };

  let [number, setNumber] = useState(type === "edit" ? "" : [1]);

  const handleShowMore = () => {
    if (number.length < 7) {
      setNumber([...number, 2]);
    }
  };

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleChangeDate = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.format("YYYY-MM-DD") };
    });
  };

  const [doctorAttendances, setDoctorAttendances] = useState([
    { day: "", start_time: "", end_time: "" },
  ]);

  let sendData = (e) => {
    e.preventDefault();

    // Step 1: Validate end time > start time
    for (let attendance of doctorAttendances) {
      if (
        attendance.start_time &&
        attendance.end_time &&
        attendance.end_time <= attendance.start_time
      ) {
        notifyError(
          `End time must be later than start time for ${
            attendance.day || "a day"
          }.`
        );
        return; // Prevent submission
      }
    }

    // Step 2: Ensure unique days
    const uniqueDays = new Set();
    for (let attendance of doctorAttendances) {
      if (attendance.day) {
        if (uniqueDays.has(attendance.day)) {
          notifyError(`The day "${attendance.day}" is already selected.`);
          return; // Prevent submission
        }
        uniqueDays.add(attendance.day);
      }
    }

    // Step 3: Clean up objects
    const cleanedAttendances = doctorAttendances.map(
      ({ day, start_time, end_time }) => ({
        day: day || "",
        start_time: start_time || "",
        end_time: end_time || "",
      })
    );

    // Step 4: Prevent submission if any object has empty fields
    for (let attendance of cleanedAttendances) {
      if (!attendance.day || !attendance.start_time || !attendance.end_time) {
        notifyError(
          "Please complete all fields for each attendance before submitting."
        );
        return; // Prevent submission
      }
    }

    // Assign cleaned data
    inp.doctor_attendances = cleanedAttendances;

    // Step 5: Submit based on type
    if (type === "add") {
      console.log("addd");
      dispatch(createDoctor({ data: inp, lang: i18n.language })).then(
        (response) => {
          if (response.payload.success) {
            path("/doctors");
          } else {
            notifyError("Failed to add doctor. Please try again.");
          }
        }
      );
    }

    if (type === "edit") {
      console.log("ediit");
      let newdata = {
        ...inp,
        _method: "PUT",
      };
      dispatch(
        updateDoctor({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        })
      ).then((response) => {
        if (response.payload.success) {
          path("/doctors");
        } else {
          notifyError("Failed to update doctor. Please try again.");
        }
      });
    }
  };

  useEffect(() => {
    console.log("test test");
    dispatch(getAllGenderList({ lang: i18n.language }));
    dispatch(getAllCategory({ lang: i18n.language }));
    dispatch(getAllWeekdays({ lang: i18n.language }));

    if (type === "edit") {
      dispatch(fetchDoctorById({ id: id }));
    }
  }, []);

  let { loadingGen, errorGen, genders } = useSelector(
    (state) => state.persistData.Commone
  );
  let { loadingCategory, errorCategory, Category } = useSelector(
    (state) => state.persistData.Commone
  );
  let { loadingWeekDays, errorWeekDays, WeekDays } = useSelector(
    (state) => state.persistData.Commone
  );

  console.log("common genders", genders);
  console.log("common Category", Category);
  console.log("common WeekDays", WeekDays);

  let {
    loadingBTN,
    error,
    dataCreate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.persistData.doctors);
  console.log("singleData", singleData);

  const path = useNavigate();
  let handleClose = (e) => {
    path(-1);
  };

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  console.log("inppppp", inp);

  useEffect(() => {
    if (type === "edit" && singleData) {
      // Extract translations and set default values
      const translations = singleData?.translations;
      console.log("translationstranslations", translations);
      const defaultValues =
        translations &&
        translations?.reduce((acc, translation) => {
          acc[`translations[${translation.locale}][name]`] = translation.name;
          return acc;
        }, {});

      // Ensure selectedLanguages includes all available locales from translations
      const allLocales = translations
        ? translations.map((translation) => translation?.locale)
        : [];
      setSelectedLanguages([...new Set([...selectedLanguages, ...allLocales])]); // Remove duplicates and add new locales

      setInp({
        ...defaultValues,
        years_count_ex: singleData.years_count_ex,
        doctors_categories: [
          { category_id: singleData?.doctors_categories[0]?.id },
        ],
        doctor_contacts: singleData.doctor_contacts,
      });
      setDoctorAttendances(singleData.doctor_attendances);
    }
  }, [type, singleData, singleData?.item?.id]);

  console.log("doctorAttendances", doctorAttendances);

  // Step 1: Create state to store selected languages
  const [selectedLanguages, setSelectedLanguages] = useState(["en"]);

  const toggleLanguage = (lang) => {
    if (selectedLanguages.includes(lang)) {
      // If the language is already selected, remove it
      setSelectedLanguages(selectedLanguages.filter((l) => l !== lang));
    } else {
      // If the language is not selected, add it
      setSelectedLanguages([...selectedLanguages, lang]);
    }
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <HeaderComponent title={type === "edit" ? "Edit Doctor" : "Add Doctor"}>
        <CheckMultiLangu
          toggleLanguage={toggleLanguage}
          selectedLanguages={selectedLanguages}
        />
      </HeaderComponent>
      <div className="two-box">
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="Details row" onSubmit={(e) => sendData(e)}>
            <div className="col-lg-3 col-sm-6 row">
              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div className="det-inp">
                  <label htmlFor="file">
                    <img
                      className="b6e5"
                      src={
                        "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                      alt="..."
                    />
                    <CloudUploadIcon className="icon ms-2" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="up"
                    placeholder="name"
                    style={{ display: "none" }}
                  />
                </div>

                <div className="form-group d-flex gap-2 justify-content-center align-items-center w-100 me-5">
                  <p>
                    {t("Status")}:<sup className="redstar">*</sup>
                  </p>
                  <Switch
                    defaultChecked={
                      type === "edit" && singleData && singleData.active == 1
                        ? true
                        : false
                    }
                    onChange={(event) => handleSwitchChange(event, "active")}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-sm-6 row">
              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"email"}
                    type={"email"}
                    label={t("Email")}
                    name={"email"}
                    required
                    defaultValue={
                      type === "edit" ? singleData && singleData.email : ""
                    }
                    onChange={(e) => getValue(e)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              {selectedLanguages.map(
                (lang) =>
                  selectedLanguages.includes(lang) && (
                    <div className="col-lg-4 col-sm-6">
                      <div className="form-group mb-4 mt-4">
                        <TextField
                          id={"text"}
                          type={"text"}
                          label={t("Name") + ` (${lang})`}
                          name={`translations[${lang}][name]`}
                          required
                          value={inp[`translations[${lang}][name]`] || ""}
                          onChange={(e) => getValue(e)}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  )
              )}

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="Gender">{t("Gender")}</InputLabel>
                    <Select
                      labelId="Gender"
                      name="gender"
                      id="Role"
                      defaultValue={
                        type === "edit" && singleData ? singleData.gender : ""
                      }
                      required
                      label="Role"
                      onChange={(e) => getValue(e)}
                    >
                      {loadingGen ? (
                        // Display a loading indicator if data is still being fetched
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        // Map over the genders array to create MenuItems for each gender option
                        genders &&
                        genders?.map((gender) => (
                          <MenuItem key={gender.id} value={gender.id}>
                            {gender.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"text"}
                    type={"number"}
                    label={t("Phone Number")}
                    name={"phone"}
                    required
                    defaultValue={
                      type === "edit"
                        ? singleData &&
                          singleData?.doctor_contacts[0]?.phone_number
                        : ""
                    }
                    onChange={(e) =>
                      setInp((prev) => {
                        const phoneValue = e.target.value;
                        return {
                          ...prev,
                          doctor_contacts:
                            prev?.doctor_contacts?.length > 0
                              ? [
                                  {
                                    ...prev.doctor_contacts[0],
                                    phone_number: phoneValue,
                                  },
                                ]
                              : [{ phone_number: phoneValue }],
                        };
                      })
                    }
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="Competence type">
                      {t("Competence type")}
                    </InputLabel>
                    <Select
                      labelId="competence_type"
                      name="competence_type"
                      id="competence_type"
                      defaultValue={
                        type === "edit" && singleData
                          ? singleData?.doctors_categories[0]?.id
                          : ""
                      }
                      required
                      label={"Competence type"}
                      onChange={(e) =>
                        setInp((prev) => {
                          const cateValue = e.target.value;
                          return {
                            ...prev,
                            doctors_categories:
                              prev?.doctors_categories?.length > 0
                                ? [
                                    {
                                      ...prev.doctors_categories[0],
                                      category_id: cateValue,
                                    },
                                  ]
                                : [{ category_id: cateValue }],
                          };
                        })
                      }
                    >
                      {loadingCategory ? (
                        // Display loading indicator if data is still loading
                        <MenuItem disabled>
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : (
                        // Map over ChronicD to dynamically create MenuItems
                        Category &&
                        Category.map((disease) => {
                          const translation = disease?.translations?.find(
                            (t) => t.locale === i18n.language
                          );

                          return (
                            <MenuItem key={disease.id} value={disease.id}>
                              {translation ? translation.name : disease.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"text"}
                    type={"text"}
                    label={t("Address")}
                    name={"location"}
                    required
                    defaultValue={
                      type === "edit"
                        ? singleData && singleData?.doctor_contacts[0]?.address
                        : ""
                    }
                    onChange={(e) =>
                      setInp((prev) => {
                        const Address = e.target.value;
                        return {
                          ...prev,
                          doctor_contacts:
                            prev?.doctor_contacts?.length > 0
                              ? [
                                  {
                                    ...prev.doctor_contacts[0],
                                    address: Address,
                                  },
                                ]
                              : [{ address: Address }],
                        };
                      })
                    }
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"text"}
                    type={"number"}
                    label={t("Years of experience")}
                    name={"years_count_ex"}
                    max={2}
                    required
                    defaultValue={
                      type === "edit"
                        ? singleData && singleData.years_count_ex
                          ? parseFloat(singleData.years_count_ex)
                          : ""
                        : ""
                    }
                    onChange={(e) => getValue(e)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel id="Date Of Birth">
                    {t("Date Of Birth")}:
                  </InputLabel>
                  <DatePicker
                    onChange={(newValue) => {
                      handleChangeDate(newValue, "date_of_birthday");
                    }}
                    defaultValue={
                      type === "edit"
                        ? dayjs(singleData && singleData?.date_of_birthday) // Format if necessary
                        : null
                    }
                  />
                </LocalizationProvider>
              </div>
              <div className="col-lg-4 col-sm-6">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel id="Certification Date">
                    {t("Certification Date")}:
                  </InputLabel>
                  <DatePicker
                    onChange={(newValue) => {
                      handleChangeDate(newValue, "certification_date");
                    }}
                    defaultValue={
                      type === "edit"
                        ? dayjs(singleData && singleData?.certification_date) // Format if necessary
                        : null
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className=" row">
              <hr className="mt-5" />

              <div className="avilable col-lg-12  ">
                <Fragment>
                  <AvailabilityManager
                    WeekDays={WeekDays || []}
                    type={type}
                    doctorAttendances={doctorAttendances}
                    setDoctorAttendances={setDoctorAttendances}
                  />
                </Fragment>
              </div>
            </div>
            <div
              className="btn-lest mt-5"
              style={{ margin: "40px !important", justifyContent: "center" }}
            >
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Craete")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Back")}
              </span>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditCreateDoctor;
